@font-face {
  font-family: "misaki";
  src: local("misaki_gothic"), url("./fonts/misaki_gothic.ttf") format("truetype");
}

.misaki {
  font-family: misaki;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.container {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form input[type="text"] {
  width: 100%;
}

.form textarea {
  width: 100%;
}

.phone-screen {
  width: 320px;
  height: auto;
  position: relative;
}

.phone-body {
  font-family: misaki;
  position: absolute;
  line-height: 1.3rem;
  padding: 0 0.5rem;
  white-space: pre-wrap;
  border: dot-dash;
}

.phone-background {
  width: 100%;
  height: 100%;
  opacity: 0.3;
  object-fit: scale-down;
}

.phone-screen-2 {
  width: 320px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: lighten;
}

.phone-body-2 {
  font-family: misaki;
  line-height: 1.3rem;
  padding: 0 0.5rem;
  white-space: pre-wrap;
  border: dot-dash;
  background-color: rgba(255, 255, 255, 0.8);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
